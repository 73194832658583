import { jsx as _jsx } from "react/jsx-runtime";
import { Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
var ProtectedRoute = function (_a) {
    var children = _a.children;
    var currentUser = useAuth().currentUser;
    if (!currentUser) {
        return _jsx(Navigate, { to: "/Login", replace: true });
    }
    return children;
};
export default ProtectedRoute;
