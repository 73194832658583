var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import CTAButton from './CTAButton';
var Section = styled.section(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 80px 20px;\n  text-align: center;\n  background-color: #f9f9f9;\n"], ["\n  padding: 80px 20px;\n  text-align: center;\n  background-color: #f9f9f9;\n"])));
var Headline = styled.h2(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 2rem;\n  margin-bottom: 1rem;\n"], ["\n  font-size: 2rem;\n  margin-bottom: 1rem;\n"])));
var Subheadline = styled.p(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 1.1rem;\n  margin-bottom: 3rem;\n  max-width: 700px;\n  margin-left: auto;\n  margin-right: auto;\n"], ["\n  font-size: 1.1rem;\n  margin-bottom: 3rem;\n  max-width: 700px;\n  margin-left: auto;\n  margin-right: auto;\n"])));
var StepsContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n"], ["\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n"])));
var Step = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  flex: 1 1 250px;\n  margin: 10px;\n  padding: 20px;\n  background-color: #fff;\n  border-radius: 10px;\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n"], ["\n  flex: 1 1 250px;\n  margin: 10px;\n  padding: 20px;\n  background-color: #fff;\n  border-radius: 10px;\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n"])));
var StepTitle = styled.h3(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-size: 1.5rem;\n  margin-bottom: 1rem;\n"], ["\n  font-size: 1.5rem;\n  margin-bottom: 1rem;\n"])));
var StepDescription = styled.p(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-size: 1rem;\n"], ["\n  font-size: 1rem;\n"])));
var HowItWorks = function (_a) {
    var onJoinWaitlist = _a.onJoinWaitlist;
    return (_jsxs(Section, { children: [_jsx(Headline, { children: "How It Works" }), _jsx(Subheadline, { children: "Saving you time & money because family comes first." }), _jsxs(StepsContainer, { children: [_jsxs(Step, { children: [_jsx(StepTitle, { children: "Tailored Meals" }), _jsx(StepDescription, { children: "Receive personalized meal recommendations based on your family preferences and diet." })] }), _jsxs(Step, { children: [_jsx(StepTitle, { children: "Automated Grocery Shopping" }), _jsx(StepDescription, { children: "We generate your shopping list and even submit it to Woolworths\u2014zero hassle." })] }), _jsxs(Step, { children: [_jsx(StepTitle, { children: "Easy Cooking" }), _jsx(StepDescription, { children: "Follow simple, family-friendly recipes designed to save time and reduce stress." })] }), _jsxs(Step, { children: [_jsx(StepTitle, { children: "Sustainability at the Core" }), _jsx(StepDescription, { children: "With Sporksy, you waste less, save money, and eat more mindfully. Better for you, better for the planet." })] })] }), _jsx(CTAButton, __assign({ onClick: onJoinWaitlist }, { children: "Get on the List \u2013 Early Access Awaits" }))] }));
};
export default HowItWorks;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
