var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components';
var OptionsContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n  align-items: center;\n  gap: 10px;\n  width: 100%;\n  min-width: 250px; /* Add a minimum width */\n\n  @media (max-width: 768px) {\n    flex-direction: column;\n    align-items: center;\n  }\n"], ["\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n  align-items: center;\n  gap: 10px;\n  width: 100%;\n  min-width: 250px; /* Add a minimum width */\n\n  @media (max-width: 768px) {\n    flex-direction: column;\n    align-items: center;\n  }\n"])));
var OptionLabel = styled.label(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  margin-bottom: 0.5rem;\n  font-size: 1rem;\n  cursor: pointer;\n  border: ", ";\n  padding: 5px;\n  border-radius: 4px;\n\n  &:focus-within {\n    outline: 2px solid #ff6f61;\n  }\n\n  input {\n    margin-right: 10px;\n    transform: scale(1.2);\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  margin-bottom: 0.5rem;\n  font-size: 1rem;\n  cursor: pointer;\n  border: ", ";\n  padding: 5px;\n  border-radius: 4px;\n\n  &:focus-within {\n    outline: 2px solid #ff6f61;\n  }\n\n  input {\n    margin-right: 10px;\n    transform: scale(1.2);\n  }\n"])), function (_a) {
    var $hasError = _a.$hasError;
    return ($hasError ? '1px solid red' : 'none');
});
var CheckboxGroup = React.memo(function (_a) {
    var name = _a.name, options = _a.options, selectedValues = _a.selectedValues, onChange = _a.onChange, maxSelections = _a.maxSelections, ariaDescribedBy = _a.ariaDescribedBy;
    var handleCheckboxChange = function (e) {
        if (maxSelections) {
            if (e.target.checked && selectedValues.length >= maxSelections) {
                // Prevent checking more than maxSelections
                e.preventDefault();
                return;
            }
        }
        onChange(e);
    };
    return (_jsx(OptionsContainer, __assign({ role: "group", "aria-describedby": ariaDescribedBy }, { children: options.map(function (option) { return (_jsxs(OptionLabel, __assign({ htmlFor: option.id, "$hasError": !!ariaDescribedBy }, { children: [_jsx("input", { type: "checkbox", id: option.id, name: name, value: option.value, checked: selectedValues.includes(option.value), onChange: handleCheckboxChange, "aria-checked": selectedValues.includes(option.value) }), option.label] }), option.id)); }) })));
});
export default CheckboxGroup;
var templateObject_1, templateObject_2;
