var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components';
var StyledTextInput = styled.input(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  padding: 10px;\n  margin: 10px 0;\n  font-size: 1rem;\n  border-radius: 4px;\n  border: 1px solid ", ";\n\n  &:focus {\n    outline: 2px solid #ff6f61;\n    border-color: #ff6f61;\n  }\n"], ["\n  width: 100%;\n  padding: 10px;\n  margin: 10px 0;\n  font-size: 1rem;\n  border-radius: 4px;\n  border: 1px solid ", ";\n\n  &:focus {\n    outline: 2px solid #ff6f61;\n    border-color: #ff6f61;\n  }\n"])), function (_a) {
    var $hasError = _a.$hasError;
    return ($hasError ? 'red' : '#ccc');
});
var TextInputField = React.memo(function (_a) {
    var name = _a.name, value = _a.value, onChange = _a.onChange, placeholder = _a.placeholder, _b = _a.required, required = _b === void 0 ? false : _b, ariaDescribedBy = _a.ariaDescribedBy;
    return (_jsx(StyledTextInput, { type: "text", name: name, value: value, onChange: onChange, placeholder: placeholder, required: required, "aria-required": required, "aria-describedby": ariaDescribedBy, "$hasError": !!ariaDescribedBy }));
});
export default TextInputField;
var templateObject_1;
