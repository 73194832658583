var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import CTAButton from './CTAButton';
import { FaLock } from 'react-icons/fa';
var Section = styled.section(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 80px 20px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  text-align: center;\n  background-color: #f9f9f9;\n"], ["\n  padding: 80px 20px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  text-align: center;\n  background-color: #f9f9f9;\n"])));
var Headline = styled.h2(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 2.5rem;\n  margin-bottom: 1rem;\n  color: #333;\n"], ["\n  font-size: 2.5rem;\n  margin-bottom: 1rem;\n  color: #333;\n"])));
var Subheadline = styled.p(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 1.2rem;\n  margin-bottom: 2rem;\n  color: #555;\n"], ["\n  font-size: 1.2rem;\n  margin-bottom: 2rem;\n  color: #555;\n"])));
var DemoImageContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n"])));
var DemoImage = styled.img(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 80%;\n  max-width: 600px;\n  margin-bottom: 2rem;\n  border-radius: 10px;\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n"], ["\n  width: 80%;\n  max-width: 600px;\n  margin-bottom: 2rem;\n  border-radius: 10px;\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n"])));
var LockIcon = styled(FaLock)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  margin-right: 8px;\n"], ["\n  margin-right: 8px;\n"])));
var AccessNote = styled.p(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  margin-top: 1rem;\n  font-size: 0.9rem;\n  color: #777;\n"], ["\n  margin-top: 1rem;\n  font-size: 0.9rem;\n  color: #777;\n"])));
var DemoSection = function (_a) {
    var onJoinWaitlist = _a.onJoinWaitlist;
    return (_jsxs(Section, { children: [_jsx(Headline, { children: "See Sporksy in Action" }), _jsx(Subheadline, { children: "Want to experience Sporksy? Join the waitlist for exclusive access to our demo, available for a limited time." }), _jsx(DemoImageContainer, { children: _jsx(DemoImage, { src: "/assets/mealplanner-dashboard.png", alt: "Sporksy Dashboard Preview" }) }), _jsxs(CTAButton, __assign({ onClick: onJoinWaitlist }, { children: [_jsx(LockIcon, {}), "Join the Waitlist and Unlock the Demo"] })), _jsx(AccessNote, { children: "Access will be granted on a case-by-case basis." })] }));
};
export default DemoSection;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
