var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
// src/hooks/useAuth.tsx
import { createContext, useContext, useEffect, useState } from 'react';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, signOut, onAuthStateChanged } from 'firebase/auth';
import { db } from '../services/firebase'; // Ensure this path is correct
import { doc, getDoc, setDoc } from 'firebase/firestore';
// Create the AuthContext with default values
var AuthContext = createContext({
    currentUser: null,
    signup: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/];
    }); }); },
    signin: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/];
    }); }); },
    signinWithGoogle: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/];
    }); }); },
    logout: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/];
    }); }); },
});
// Custom hook to use the AuthContext
export var useAuth = function () {
    return useContext(AuthContext);
};
// AuthProvider component to wrap around the app
export var AuthProvider = function (_a) {
    var children = _a.children;
    var _b = useState(null), currentUser = _b[0], setCurrentUser = _b[1];
    var _c = useState(true), loading = _c[0], setLoading = _c[1];
    var auth = getAuth();
    // Function to handle user signup
    var signup = function (email, password) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, createUserWithEmailAndPassword(auth, email, password)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    // Function to handle user signin with email/password
    var signin = function (email, password) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, signInWithEmailAndPassword(auth, email, password)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    // Function to handle user signin with Google
    var signinWithGoogle = function () { return __awaiter(void 0, void 0, void 0, function () {
        var provider;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    provider = new GoogleAuthProvider();
                    return [4 /*yield*/, signInWithPopup(auth, provider)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    // Function to handle user logout
    var logout = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, signOut(auth)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    // Listen for authentication state changes
    useEffect(function () {
        var unsubscribe = onAuthStateChanged(auth, function (user) { return __awaiter(void 0, void 0, void 0, function () {
            var userDocRef, userDocSnap;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setCurrentUser(user);
                        if (!user) return [3 /*break*/, 3];
                        userDocRef = doc(db, 'users', user.uid);
                        return [4 /*yield*/, getDoc(userDocRef)];
                    case 1:
                        userDocSnap = _a.sent();
                        if (!!userDocSnap.exists()) return [3 /*break*/, 3];
                        return [4 /*yield*/, setDoc(userDocRef, {
                                familyMembers: [],
                                updatedAt: new Date(),
                            })];
                    case 2:
                        _a.sent();
                        console.log('Created new user document in Firestore.');
                        _a.label = 3;
                    case 3:
                        setLoading(false);
                        return [2 /*return*/];
                }
            });
        }); });
        // Cleanup subscription on unmount
        return function () { return unsubscribe(); };
    }, [auth]);
    // Value to be passed to AuthContext.Provider
    var value = {
        currentUser: currentUser,
        signup: signup,
        signin: signin,
        signinWithGoogle: signinWithGoogle,
        logout: logout,
    };
    if (loading) {
        // You can replace this with a spinner or loading screen
        return _jsx("div", { children: "Loading..." });
    }
    return (_jsx(AuthContext.Provider, __assign({ value: value }, { children: children })));
};
