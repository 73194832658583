import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// client/src/components/landingpage/LandingPage.tsx
import { useState } from 'react';
import HeroSection from './HeroSection';
import HowItWorks from './HowItWorks';
import DemoSection from './DemoSection';
import AboutUs from './AboutUs';
import Features from './Features';
import FAQ from './FAQ';
import Testimonials from './Testimonials'; // Optional
import Footer from './Footer';
import Survey from './Survey'; // Updated import
import SignupModal from './SignupModal'; // Import the SignupModal
import MealPlanningQuiz from '../quiz/MealPlanningQuiz'; // Import the Quiz
var LandingPage = function () {
    var _a = useState(false), isModalOpen = _a[0], setIsModalOpen = _a[1]; // State to manage modal visibility
    // Array of image paths for the slideshow
    var slideshowImages = [
        '/assets/pink-avocado.png',
        /*  '/assets/image2.png',
          '/assets/image3.png',
          '/assets/image4.png',
          '/assets/image5.png',
          '/assets/image6.png',
          '/assets/image7.png',
          '/assets/image8.png',
          '/assets/image9.png',
          '/assets/image10.png', */
    ];
    // Handler to open the SignupModal
    var handleJoinWaitlist = function () {
        setIsModalOpen(true);
    };
    // Handler to close the SignupModal
    var handleCloseModal = function () {
        setIsModalOpen(false);
    };
    return (_jsxs(_Fragment, { children: [_jsx(HeroSection, { images: slideshowImages, onJoinWaitlist: handleJoinWaitlist }), _jsx(HowItWorks, { onJoinWaitlist: handleJoinWaitlist }), _jsx(MealPlanningQuiz, { onJoinWaitlist: handleJoinWaitlist }), " ", _jsx(DemoSection, { onJoinWaitlist: handleJoinWaitlist }), _jsx(AboutUs, { onJoinWaitlist: handleJoinWaitlist }), _jsx(Features, { onJoinWaitlist: handleJoinWaitlist }), _jsx(FAQ, {}), _jsx(Survey, {}), Testimonials && _jsx(Testimonials, {}), " ", _jsx(Footer, {}), " ", isModalOpen && (_jsx(SignupModal, { onClose: handleCloseModal, customText: "Join our waitlist by entering your name and email" }))] }));
};
export default LandingPage;
