var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// src/components/HeroSection.tsx
import { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import CTAButton from './CTAButton';
// Keyframe for fade-in and fade-out effect
var fadeInOut = keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  0% { opacity: 0; }\n  20% { opacity: 1; }\n  80% { opacity: 1; }\n  100% { opacity: 0; }\n"], ["\n  0% { opacity: 0; }\n  20% { opacity: 1; }\n  80% { opacity: 1; }\n  100% { opacity: 0; }\n"])));
var HeroContainer = styled.section(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  height: 90vh;\n  width: 100%;\n  overflow: hidden;\n"], ["\n  position: relative;\n  height: 90vh;\n  width: 100%;\n  overflow: hidden;\n"])));
// Styling for each image in the slideshow
var SlideImage = styled.img(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n  opacity: ", ";\n  transition: opacity 2s ease-in-out;\n"], ["\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n  opacity: ", ";\n  transition: opacity 2s ease-in-out;\n"])), function (_a) {
    var $isVisible = _a.$isVisible;
    return ($isVisible ? 1 : 0);
});
var Overlay = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(0, 0, 0, 0.5);\n"], ["\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(0, 0, 0, 0.5);\n"])));
var HeroContent = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  width: 90%;\n  max-width: 800px;\n  transform: translate(-50%, -50%);\n  color: #fff;\n  text-align: center;\n"], ["\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  width: 90%;\n  max-width: 800px;\n  transform: translate(-50%, -50%);\n  color: #fff;\n  text-align: center;\n"])));
var Headline = styled.h1(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-size: 2.5rem;\n  margin-bottom: 1rem;\n"], ["\n  font-size: 2.5rem;\n  margin-bottom: 1rem;\n"])));
var Subheadline = styled.p(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-size: 1.2rem;\n  margin-bottom: 2rem;\n"], ["\n  font-size: 1.2rem;\n  margin-bottom: 2rem;\n"])));
var FloatingCTA = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  position: fixed;\n  bottom: 20px;\n  right: 20px;\n  z-index: 1000;\n"], ["\n  position: fixed;\n  bottom: 20px;\n  right: 20px;\n  z-index: 1000;\n"])));
var HeroSection = function (_a) {
    var images = _a.images, onJoinWaitlist = _a.onJoinWaitlist;
    var _b = useState(0), currentImageIndex = _b[0], setCurrentImageIndex = _b[1];
    useEffect(function () {
        var intervalId = setInterval(function () {
            setCurrentImageIndex(function (prevIndex) { return (prevIndex + 1) % images.length; }); // Loop through images
        }, 5000); // Change image every 5 seconds
        return function () { return clearInterval(intervalId); }; // Clean up interval on component unmount
    }, [images.length]);
    return (_jsxs(HeroContainer, { children: [images.map(function (image, index) { return (_jsx(SlideImage, { src: image, "$isVisible": index === currentImageIndex }, image)); }), _jsx(Overlay, {}), _jsxs(HeroContent, { children: [_jsx(Headline, { children: "Transform Your Mealtime: Effortless, Personalized, and Stress-Free." }), _jsx(Subheadline, { children: "Busy? Let Sporksy take care of meal planning and grocery shopping, so you can focus on what matters most\u2014family and enjoying great food." }), _jsx(CTAButton, __assign({ onClick: onJoinWaitlist }, { children: "Join the Waitlist and Take Control of Mealtime" }))] }), _jsx(FloatingCTA, { children: _jsx(CTAButton, __assign({ onClick: onJoinWaitlist }, { children: "Join the Waitlist" })) })] }));
};
export default HeroSection;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
