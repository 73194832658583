var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { FaFacebookF, FaTwitter, FaInstagram } from 'react-icons/fa';
var FooterContainer = styled.footer(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 40px 20px;\n  background-color: #333;\n  color: #fff;\n  text-align: center;\n"], ["\n  padding: 40px 20px;\n  background-color: #333;\n  color: #fff;\n  text-align: center;\n"])));
var Links = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-bottom: 1rem;\n\n  a {\n    color: #fff;\n    margin: 0 10px;\n    text-decoration: none;\n\n    &:hover {\n      text-decoration: underline;\n    }\n  }\n"], ["\n  margin-bottom: 1rem;\n\n  a {\n    color: #fff;\n    margin: 0 10px;\n    text-decoration: none;\n\n    &:hover {\n      text-decoration: underline;\n    }\n  }\n"])));
var SocialLinks = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-bottom: 1rem;\n\n  a {\n    color: #fff;\n    margin: 0 5px;\n    font-size: 1.2rem;\n\n    &:hover {\n      color: #ff6f61;\n    }\n  }\n"], ["\n  margin-bottom: 1rem;\n\n  a {\n    color: #fff;\n    margin: 0 5px;\n    font-size: 1.2rem;\n\n    &:hover {\n      color: #ff6f61;\n    }\n  }\n"])));
var Footer = function () {
    return (_jsxs(FooterContainer, { children: [_jsxs(Links, { children: [_jsx("a", __assign({ href: "/contact" }, { children: "Contact Us" })), " | ", _jsx("a", __assign({ href: "/terms" }, { children: "Terms and Conditions" })), " | ", _jsx("a", __assign({ href: "/privacy" }, { children: "Privacy Policy" }))] }), _jsxs(SocialLinks, { children: [_jsx("a", __assign({ href: "https://facebook.com/sporksy", "aria-label": "Facebook" }, { children: _jsx(FaFacebookF, {}) })), _jsx("a", __assign({ href: "https://twitter.com/sporksy", "aria-label": "Twitter" }, { children: _jsx(FaTwitter, {}) })), _jsx("a", __assign({ href: "https://instagram.com/sporksy", "aria-label": "Instagram" }, { children: _jsx(FaInstagram, {}) }))] }), _jsxs("p", { children: ["\u00A9 ", new Date().getFullYear(), " Sporksy. All rights reserved."] })] }));
};
export default Footer;
var templateObject_1, templateObject_2, templateObject_3;
