// src/firebase.ts
import { initializeApp } from 'firebase/app';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithPopup, signInWithRedirect, getRedirectResult, GoogleAuthProvider, onAuthStateChanged, } from 'firebase/auth';
import { getFirestore, doc, getDoc, setDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import firebaseConfig from './firebaseconfig';
// Initialize Firebase App
var app = initializeApp(firebaseConfig);
// Initialize Firebase Services
var auth = getAuth(app);
var db = getFirestore(app);
var storage = getStorage(app); // Initialize storage
// Export Firebase Auth Providers and Methods
export { auth, db, storage, // Export storage
createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, signInWithPopup, signInWithRedirect, getRedirectResult, GoogleAuthProvider, doc, getDoc, setDoc, updateDoc, serverTimestamp, storageRef, uploadBytes, getDownloadURL, deleteObject, };
