import { jsx as _jsx } from "react/jsx-runtime";
// src/index.tsx
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css'; // Include if you have global styles
var rootElement = document.getElementById('root');
if (!rootElement) {
    throw new Error('Failed to find the root element');
}
var root = ReactDOM.createRoot(rootElement);
root.render(_jsx(React.StrictMode, { children: _jsx(App, {}) }));
