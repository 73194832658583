var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import styled from 'styled-components';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../../services/firebase';
import ProgressBar from './ProgressBar';
import SurveyStep from './SurveyStep';
import { toast } from 'react-toastify';
import { SurveyContainer } from '../../styles/SurveyContainer';
import 'react-toastify/dist/ReactToastify.css';
// Define mandatory questions per step
var mandatoryQuestions = {
    0: ['mealPlanning', 'groceryShopping'],
    1: ['mealPlanningChallenges', 'mealPlanningStress'],
    2: ['currentGrocerySpending', 'willingToPay'],
    3: ['valuableFeatures'],
    4: ['sustainabilityImportance'],
    5: ['name', 'email'], // Added mandatory fields for name and email in the final step
};
// Define total steps for the survey
var totalSteps = 6;
var SurveyTitle = styled.h2(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-size: 2rem;\n  margin-bottom: 1rem;\n  color: #333;\n  text-align: center;\n"], ["\n  font-size: 2rem;\n  margin-bottom: 1rem;\n  color: #333;\n  text-align: center;\n"])));
var SurveyDescription = styled.p(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 1.1rem;\n  margin-bottom: 2rem;\n  color: #555;\n  text-align: center;\n"], ["\n  font-size: 1.1rem;\n  margin-bottom: 2rem;\n  color: #555;\n  text-align: center;\n"])));
var StyledButtonContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  margin-top: 20px;\n\n  @media (max-width: 600px) {\n    flex-direction: column;\n    align-items: center;\n    width: 100%;\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n  margin-top: 20px;\n\n  @media (max-width: 600px) {\n    flex-direction: column;\n    align-items: center;\n    width: 100%;\n  }\n"])));
var StyledButton = styled.button(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  background-color: ", ";\n  color: ", ";\n  border: none;\n  border-radius: 4px;\n  padding: 10px 20px;\n  font-size: 1rem;\n  cursor: pointer;\n  margin: 10px 10px;\n  min-width: 120px;\n  transition: background-color 0.3s ease;\n\n  &:hover,\n  &:focus {\n    background-color: ", ";\n    outline: none;\n  }\n\n  &:focus {\n    outline: 2px solid #ff6f61;\n  }\n\n  @media (max-width: 600px) {\n    width: 100%;\n    margin: 10px 0;\n  }\n\n  &:disabled {\n    background-color: #ddd;\n    cursor: not-allowed;\n  }\n"], ["\n  background-color: ", ";\n  color: ", ";\n  border: none;\n  border-radius: 4px;\n  padding: 10px 20px;\n  font-size: 1rem;\n  cursor: pointer;\n  margin: 10px 10px;\n  min-width: 120px;\n  transition: background-color 0.3s ease;\n\n  &:hover,\n  &:focus {\n    background-color: ", ";\n    outline: none;\n  }\n\n  &:focus {\n    outline: 2px solid #ff6f61;\n  }\n\n  @media (max-width: 600px) {\n    width: 100%;\n    margin: 10px 0;\n  }\n\n  &:disabled {\n    background-color: #ddd;\n    cursor: not-allowed;\n  }\n"])), function (_a) {
    var $primary = _a.$primary;
    return ($primary ? '#ff6f61' : '#ccc');
}, function (_a) {
    var $primary = _a.$primary;
    return ($primary ? '#fff' : '#333');
}, function (_a) {
    var $primary = _a.$primary;
    return ($primary ? '#ff4b3a' : '#b3b3b3');
});
var Survey = function () {
    var _a = useState(0), currentStep = _a[0], setCurrentStep = _a[1];
    var _b = useState({}), errors = _b[0], setErrors = _b[1];
    var _c = useState({
        mealPlanning: '',
        groceryShopping: '',
        mealPlanningTime: '',
        mealInspiration: '',
        mealPlanningChallenges: [],
        mealPlanningStress: 0,
        foodWasteFrequency: '',
        interestInService: '',
        currentGrocerySpending: '',
        willingToPay: '',
        valuableFeatures: [],
        sustainabilityImportance: '',
        mealPredictionAppeal: '',
        autoIngredientDelivery: '',
        easeOfMealPlanning: '',
        changeMealPlanning: '',
        receiveUpdates: '',
        additionalFeedback: '',
        desiredFeatures: '',
        name: '',
        email: '', // New field for email
    }), responses = _c[0], setResponses = _c[1];
    var _d = useState(false), isSubmitting = _d[0], setIsSubmitting = _d[1];
    // Handle form field changes
    var handleChange = function (e) {
        var _a, _b, _c, _d, _e;
        var _f;
        var target = e.target;
        var name = target.name;
        var value = target.value;
        if (target instanceof HTMLInputElement && target.type === 'checkbox') {
            var checked = target.checked;
            var currentValues = responses[name];
            if (checked) {
                setResponses(__assign(__assign({}, responses), (_a = {}, _a[name] = __spreadArray(__spreadArray([], currentValues, true), [value], false), _a)));
            }
            else {
                setResponses(__assign(__assign({}, responses), (_b = {}, _b[name] = currentValues.filter(function (item) { return item !== value; }), _b)));
            }
        }
        else if (target.type === 'radio') {
            if (name === 'mealPlanningStress') {
                setResponses(__assign(__assign({}, responses), (_c = {}, _c[name] = parseInt(value, 10), _c)));
            }
            else {
                setResponses(__assign(__assign({}, responses), (_d = {}, _d[name] = value, _d)));
            }
        }
        else {
            setResponses(__assign(__assign({}, responses), (_e = {}, _e[name] = value, _e)));
        }
        // Clear error for the field if user interacts
        if ((_f = mandatoryQuestions[currentStep]) === null || _f === void 0 ? void 0 : _f.includes(name)) {
            setErrors(function (prevErrors) {
                var _a;
                return (__assign(__assign({}, prevErrors), (_a = {}, _a[name] = '', _a)));
            });
        }
    };
    // Validate the current step
    var validateStep = function () {
        var requiredFields = mandatoryQuestions[currentStep] || [];
        var newErrors = {};
        requiredFields.forEach(function (field) {
            var value = responses[field];
            if ((typeof value === 'string' && value.trim() === '') ||
                (Array.isArray(value) && value.length === 0) ||
                (typeof value === 'number' && isNaN(value))) {
                newErrors[field] = 'This field is required.';
            }
        });
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    // Handle moving to the next step
    var handleNext = function () {
        if (validateStep()) {
            setCurrentStep(function (prev) { return prev + 1; });
        }
    };
    // Handle moving to the previous step
    var handlePrevious = function () {
        setCurrentStep(function (prev) { return prev - 1; });
    };
    // Handle survey submission
    var handleSurveySubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        var error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!validateStep())
                        return [2 /*return*/];
                    setIsSubmitting(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    console.log('Submitting survey with responses:', responses);
                    // Save the survey data to Firebase
                    return [4 /*yield*/, addDoc(collection(db, 'surveys'), responses)];
                case 2:
                    // Save the survey data to Firebase
                    _a.sent();
                    toast.success('Survey submitted successfully!', {
                        position: 'top-center',
                        autoClose: 5000,
                    });
                    return [3 /*break*/, 5];
                case 3:
                    error_1 = _a.sent();
                    console.error('Error submitting survey:', error_1);
                    toast.error('Failed to submit survey. Please try again later.', {
                        position: 'top-center',
                        autoClose: 5000,
                    });
                    return [3 /*break*/, 5];
                case 4:
                    setIsSubmitting(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var progressPercentage = ((currentStep + 1) / totalSteps) * 100;
    return (_jsxs(SurveyContainer, { children: [_jsx(SurveyTitle, { children: "We'd Love Your Feedback!" }), _jsx(SurveyDescription, { children: "Help us improve our meal planning services by completing this survey." }), _jsx(ProgressBar, { currentStep: currentStep, totalSteps: totalSteps }), _jsx(SurveyStep, { step: currentStep, responses: responses, handleChange: handleChange, errors: errors }), _jsxs(StyledButtonContainer, { children: [currentStep > 0 && (_jsx(StyledButton, __assign({ onClick: handlePrevious, "aria-label": "Previous Step" }, { children: "Previous" }))), currentStep < totalSteps - 1 ? (_jsx(StyledButton, __assign({ "$primary": true, onClick: handleNext, "aria-label": "Next Step" }, { children: "Next" }))) : (_jsx(StyledButton, __assign({ "$primary": true, onClick: handleSurveySubmit, disabled: isSubmitting, "aria-label": "Submit Survey" }, { children: isSubmitting ? 'Submitting...' : 'Submit Survey' })))] })] }));
};
export default Survey;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
