var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// client/src/components/ShoppingList.tsx
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { Container, Title, LoadingContainer, ErrorContainer, EmptyContainer, Spinner, ErrorMessage, RetryButton, StyledTable, Footer, } from './ShoppingList.styles';
var ShoppingList = function () {
    var location = useLocation();
    // State management
    var _a = useState([]), shoppingList = _a[0], setShoppingList = _a[1];
    var _b = useState(true), loading = _b[0], setLoading = _b[1];
    var _c = useState(null), error = _c[0], setError = _c[1];
    // Extract token from URL query parameters
    useEffect(function () {
        var queryParams = new URLSearchParams(location.search);
        var token = queryParams.get('token');
        if (!token) {
            setError('Invalid request. Token is missing.');
            setLoading(false);
            return;
        }
        // Function to fetch the shopping list
        var fetchShoppingList = function () { return __awaiter(void 0, void 0, void 0, function () {
            var response, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        return [4 /*yield*/, axios.get('/api/generate-shopping-list', {
                                params: { token: token },
                                headers: {
                                    'Authorization': "Bearer ".concat(token),
                                },
                            })];
                    case 1:
                        response = _a.sent();
                        // Assuming the backend returns { shoppingList: ShoppingListItem[] }
                        setShoppingList(response.data.shoppingList);
                        return [3 /*break*/, 4];
                    case 2:
                        err_1 = _a.sent();
                        if (err_1.response) {
                            // Server responded with a status other than 2xx
                            setError(err_1.response.data.error || 'Failed to generate shopping list.');
                        }
                        else if (err_1.request) {
                            // Request was made but no response received
                            setError('No response from server. Please try again later.');
                        }
                        else {
                            // Something else happened
                            setError('An unexpected error occurred.');
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        setLoading(false);
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        // Initiate fetching the shopping list
        fetchShoppingList();
    }, [location.search]);
    // Handler for retrying the request
    var handleRetry = function () {
        setLoading(true);
        setError(null);
        var queryParams = new URLSearchParams(location.search);
        var token = queryParams.get('token');
        if (!token) {
            setError('Invalid request. Token is missing.');
            setLoading(false);
            return;
        }
        // Re-fetch the shopping list
        var fetchShoppingList = function () { return __awaiter(void 0, void 0, void 0, function () {
            var response, err_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        return [4 /*yield*/, axios.get('/api/generate-shopping-list', {
                                params: { token: token },
                                headers: {
                                    'Authorization': "Bearer ".concat(token),
                                },
                            })];
                    case 1:
                        response = _a.sent();
                        setShoppingList(response.data.shoppingList);
                        return [3 /*break*/, 4];
                    case 2:
                        err_2 = _a.sent();
                        if (err_2.response) {
                            setError(err_2.response.data.error || 'Failed to generate shopping list.');
                        }
                        else if (err_2.request) {
                            setError('No response from server. Please try again later.');
                        }
                        else {
                            setError('An unexpected error occurred.');
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        setLoading(false);
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        fetchShoppingList();
    };
    // Render loading state
    if (loading) {
        return (_jsxs("div", __assign({ className: Container }, { children: [_jsx("h1", __assign({ className: Title }, { children: "Your Optimized Shopping List" })), _jsxs("div", __assign({ className: LoadingContainer }, { children: [_jsx("div", { className: Spinner }), _jsx("p", { children: "Generating your shopping list..." })] }))] })));
    }
    // Render error state
    if (error) {
        return (_jsxs("div", __assign({ className: Container }, { children: [_jsx("h1", __assign({ className: Title }, { children: "Your Optimized Shopping List" })), _jsxs("div", __assign({ className: ErrorContainer }, { children: [_jsx("p", __assign({ className: ErrorMessage }, { children: error })), _jsx("button", __assign({ className: RetryButton, onClick: handleRetry }, { children: "Retry" }))] }))] })));
    }
    // Render empty shopping list
    if (shoppingList.length === 0) {
        return (_jsxs("div", __assign({ className: Container }, { children: [_jsx("h1", __assign({ className: Title }, { children: "Your Optimized Shopping List" })), _jsxs("div", __assign({ className: EmptyContainer }, { children: [_jsx("p", { children: "No items found in your shopping list." }), _jsx("button", __assign({ className: RetryButton, onClick: handleRetry }, { children: "Generate Again" }))] }))] })));
    }
    // Render the shopping list table
    return (_jsxs("div", __assign({ className: Container }, { children: [_jsx("h1", __assign({ className: Title }, { children: "Your Optimized Shopping List" })), _jsxs("table", __assign({ className: StyledTable }, { children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", { children: "Ingredient" }), _jsx("th", { children: "Product" }), _jsx("th", { children: "Quantity" }), _jsx("th", { children: "Unit" }), _jsx("th", { children: "Price" }), _jsx("th", { children: "Total" })] }) }), _jsx("tbody", { children: shoppingList.map(function (item, index) { return (_jsxs("tr", { children: [_jsx("td", { children: item.ingredient }), _jsx("td", { children: item.productName }), _jsx("td", { children: item.quantity }), _jsx("td", { children: item.unit }), _jsxs("td", { children: ["$", item.price.toFixed(2)] }), _jsxs("td", { children: ["$", item.total.toFixed(2)] })] }, index)); }) })] })), _jsx("div", __assign({ className: Footer }, { children: _jsx("p", { children: "Thank you for using Sporksy! Happy Shopping! \uD83D\uDED2\u2728" }) }))] })));
};
export default ShoppingList;
