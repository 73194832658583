var firebaseConfig = {
    apiKey: "AIzaSyCXiv7UkjcraxlRQ_IJzFb1VfknA_a9PeU",
    authDomain: "sporksy-9dae8.firebaseapp.com",
    projectId: "sporksy-9dae8",
    storageBucket: "sporksy-9dae8.appspot.com",
    messagingSenderId: "1084544066805",
    appId: "1:1084544066805:web:95dcf45c43a3fc009e11bc",
    measurementId: "G-02XZ2CT7SS"
};
export default firebaseConfig;
