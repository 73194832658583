var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// src/components/Blog/Blog.tsx
import { useEffect, useState } from 'react';
import { BlogContainer, BlogTitle, PostsList, Loader, ErrorMessage, } from './Blog.styles';
import BlogPost from './BlogPost'; // Ensure BlogPost is correctly imported
import { db } from '../../services/firebase';
import { collection, getDocs, query, orderBy } from 'firebase/firestore'; // Correct imports
import { toast } from 'react-toastify';
var Blog = function () {
    var _a = useState([]), posts = _a[0], setPosts = _a[1];
    var _b = useState(true), loading = _b[0], setLoading = _b[1];
    var _c = useState(''), error = _c[0], setError = _c[1];
    useEffect(function () {
        var fetchPosts = function () { return __awaiter(void 0, void 0, void 0, function () {
            var postsCollection, q, querySnapshot, fetchedPosts_1, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        postsCollection = collection(db, 'blogPosts');
                        q = query(postsCollection, orderBy('date', 'desc'));
                        return [4 /*yield*/, getDocs(q)];
                    case 1:
                        querySnapshot = _a.sent();
                        fetchedPosts_1 = [];
                        querySnapshot.forEach(function (docSnap) {
                            var data = docSnap.data();
                            fetchedPosts_1.push({
                                id: docSnap.id,
                                title: data.title,
                                author: data.author,
                                date: data.date.toDate().toISOString(),
                                excerpt: data.excerpt,
                            });
                        });
                        setPosts(fetchedPosts_1);
                        return [3 /*break*/, 4];
                    case 2:
                        err_1 = _a.sent();
                        console.error(err_1);
                        setError('Failed to load blog posts. Please try again later.');
                        toast.error('Failed to load blog posts. Please try again later.');
                        return [3 /*break*/, 4];
                    case 3:
                        setLoading(false);
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        fetchPosts();
    }, []);
    if (loading) {
        return (_jsx(BlogContainer, { children: _jsx(Loader, { children: "Loading blog posts..." }) }));
    }
    if (error) {
        return (_jsx(BlogContainer, { children: _jsx(ErrorMessage, { children: error }) }));
    }
    return (_jsxs(BlogContainer, { children: [_jsx(BlogTitle, { children: "Our Blog" }), posts.length === 0 ? (_jsx("p", { children: "No blog posts available at the moment." })) : (_jsx(PostsList, { children: posts.map(function (post) { return (_jsx(BlogPost, { id: post.id, title: post.title, author: post.author, date: post.date, excerpt: post.excerpt }, post.id)); }) }))] }));
};
export default Blog;
