var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import GlobalStyle from './styles/GlobalStyle';
import { ThemeProvider } from 'styled-components';
import { theme } from './styles/theme';
import Login from './components/account/login';
import Header from './components/header/Header';
import LandingPage from './components/landingpage/LandingPage';
import MealPlanner from './components/mealplanner/MealPlanner';
import Account from './components/account/Account';
import Blog from './components/blog/Blog';
import ShoppingList from './components/mealplanner/ShoppingList';
import AdminDashboard from './components/AdminDashboard/AdminDashboard';
import Survey from './components/landingpage/Survey'; // Import Survey component
import MealPlanningQuiz from './components/quiz/MealPlanningQuiz'; // Import Quiz component
import { AuthProvider, useAuth } from './hooks/useAuth';
import ProtectedRoute from './components/ProtectedRoute';
var AppRoutes = function () {
    var currentUser = useAuth().currentUser;
    // Define the onJoinWaitlist function to pass to the MealPlanningQuiz
    var handleJoinWaitlist = function () {
        console.log('User wants to join the waitlist');
        // You can show a modal, redirect, or perform other actions here
    };
    // Function to check if the user is an admin
    var isAdmin = (currentUser === null || currentUser === void 0 ? void 0 : currentUser.email) === 'kaifus87@gmail.com'; // Replace with proper admin-check logic
    return (_jsxs(Routes, { children: [_jsx(Route, { path: "/", element: _jsx(LandingPage, {}) }), _jsx(Route, { path: "/blog", element: _jsx(Blog, {}) }), _jsx(Route, { path: "/survey", element: _jsx(Survey, {}) }), _jsx(Route, { path: "/login", element: _jsx(Login, {}) }), _jsx(Route, { path: "/shopping-list", element: _jsx(ShoppingList, {}) }), _jsx(Route, { path: "*", element: _jsx("div", { children: "404 Not Found" }) }), _jsx(Route, { path: "/account", element: _jsx(ProtectedRoute, { children: _jsx(Account, {}) }) }), _jsx(Route, { path: "/quiz", element: _jsx(MealPlanningQuiz, { onJoinWaitlist: handleJoinWaitlist }) }), ' ', _jsx(Route, { path: "/mealplanner", element: _jsx(MealPlanner, {}) }), ' ', isAdmin && _jsx(Route, { path: "/admin", element: _jsx(AdminDashboard, {}) }), ' '] }));
};
var App = function () {
    return (_jsx(ThemeProvider, __assign({ theme: theme }, { children: _jsx(AuthProvider, { children: _jsxs(Router, { children: [_jsx(GlobalStyle, {}), _jsx(Header, {}), _jsx(AppRoutes, {})] }) }) })));
};
export default App;
