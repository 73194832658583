var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
// src/components/Blog/Blog.styles.ts
import styled from 'styled-components';
export var BlogContainer = styled.main(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  max-width: 1000px;\n  margin: 50px auto;\n  padding: 2rem;\n  background-color: ", ";\n  border-radius: 8px;\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n  font-family: Arial, sans-serif;\n\n  @media (max-width: ", ") {\n    margin: 20px;\n    padding: 1rem;\n  }\n"], ["\n  max-width: 1000px;\n  margin: 50px auto;\n  padding: 2rem;\n  background-color: ", ";\n  border-radius: 8px;\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n  font-family: Arial, sans-serif;\n\n  @media (max-width: ", ") {\n    margin: 20px;\n    padding: 1rem;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.background;
}, function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.mobile;
});
export var BlogTitle = styled.h1(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: ", ";\n  font-size: 2.5rem;\n  text-align: center;\n  margin-bottom: 2rem;\n"], ["\n  color: ", ";\n  font-size: 2.5rem;\n  text-align: center;\n  margin-bottom: 2rem;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.primary;
});
export var PostsList = styled.ul(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  list-style: none;\n  display: grid;\n  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));\n  gap: 2rem;\n\n  @media (max-width: ", ") {\n    grid-template-columns: 1fr;\n  }\n"], ["\n  list-style: none;\n  display: grid;\n  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));\n  gap: 2rem;\n\n  @media (max-width: ", ") {\n    grid-template-columns: 1fr;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.mobile;
});
export var PostItem = styled.li(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  background-color: #f9f9f9;\n  padding: 1.5rem;\n  border-radius: 8px;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);\n  transition: transform 0.3s ease, box-shadow 0.3s ease;\n\n  &:hover,\n  &:focus-within {\n    transform: translateY(-5px);\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  }\n"], ["\n  background-color: #f9f9f9;\n  padding: 1.5rem;\n  border-radius: 8px;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);\n  transition: transform 0.3s ease, box-shadow 0.3s ease;\n\n  &:hover,\n  &:focus-within {\n    transform: translateY(-5px);\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  }\n"])));
export var PostTitle = styled.h2(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  color: ", ";\n  font-size: 1.8rem;\n  margin-bottom: 0.5rem;\n"], ["\n  color: ", ";\n  font-size: 1.8rem;\n  margin-bottom: 0.5rem;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.primary;
});
export var PostMeta = styled.p(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  color: #666666;\n  font-size: 0.9rem;\n  margin-bottom: 1rem;\n"], ["\n  color: #666666;\n  font-size: 0.9rem;\n  margin-bottom: 1rem;\n"])));
export var PostExcerpt = styled.p(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  color: #333333;\n  font-size: 1rem;\n  margin-bottom: 1.5rem;\n"], ["\n  color: #333333;\n  font-size: 1rem;\n  margin-bottom: 1.5rem;\n"])));
export var ReadMoreLink = styled.a(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  text-decoration: none;\n  color: ", ";\n  font-weight: bold;\n\n  &:hover,\n  &:focus {\n    text-decoration: underline;\n  }\n\n  &:focus {\n    outline: 2px solid ", ";\n    outline-offset: 2px;\n  }\n"], ["\n  text-decoration: none;\n  color: ", ";\n  font-weight: bold;\n\n  &:hover,\n  &:focus {\n    text-decoration: underline;\n  }\n\n  &:focus {\n    outline: 2px solid ", ";\n    outline-offset: 2px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.primary;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.focus;
});
export var Loader = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  text-align: center;\n  font-size: 1.2rem;\n  color: ", ";\n"], ["\n  text-align: center;\n  font-size: 1.2rem;\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.text;
});
export var ErrorMessage = styled.p(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  text-align: center;\n  color: #e63946;\n  font-size: 1rem;\n"], ["\n  text-align: center;\n  color: #e63946;\n  font-size: 1rem;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
