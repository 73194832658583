// src/components/header/Header.styles.ts
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { Link } from 'react-router-dom';
// Define the styled components
export var Nav = styled.nav(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background: #ffffff;\n  height: 60px;\n  display: flex;\n  justify-content: space-between;\n  padding: 0 20px;\n  position: relative;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n"], ["\n  background: #ffffff;\n  height: 60px;\n  display: flex;\n  justify-content: space-between;\n  padding: 0 20px;\n  position: relative;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n"])));
export var Logo = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 24px;\n  font-weight: bold;\n  color: #333333;\n  cursor: pointer;\n"], ["\n  font-size: 24px;\n  font-weight: bold;\n  color: #333333;\n  cursor: pointer;\n"])));
export var Hamburger = styled.button(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: none;\n  flex-direction: column;\n  justify-content: space-around;\n  width: 30px;\n  height: 25px;\n  background: transparent;\n  border: none;\n  cursor: pointer;\n  padding: 0;\n  z-index: 20;\n\n  div {\n    width: 30px;\n    height: 3px;\n    background: #333333;\n    border-radius: 2px;\n    transition: all 0.3s linear;\n    position: relative;\n    transform-origin: 1px;\n\n    :first-child {\n      transform: ", ";\n    }\n\n    :nth-child(2) {\n      opacity: ", ";\n      transform: ", ";\n    }\n\n    :nth-child(3) {\n      transform: ", ";\n    }\n  }\n\n  @media (max-width: 768px) {\n    display: flex;\n  }\n"], ["\n  display: none;\n  flex-direction: column;\n  justify-content: space-around;\n  width: 30px;\n  height: 25px;\n  background: transparent;\n  border: none;\n  cursor: pointer;\n  padding: 0;\n  z-index: 20;\n\n  div {\n    width: 30px;\n    height: 3px;\n    background: #333333;\n    border-radius: 2px;\n    transition: all 0.3s linear;\n    position: relative;\n    transform-origin: 1px;\n\n    :first-child {\n      transform: ", ";\n    }\n\n    :nth-child(2) {\n      opacity: ", ";\n      transform: ", ";\n    }\n\n    :nth-child(3) {\n      transform: ", ";\n    }\n  }\n\n  @media (max-width: 768px) {\n    display: flex;\n  }\n"])), function (_a) {
    var $isOpen = _a.$isOpen;
    return ($isOpen ? 'rotate(45deg)' : 'rotate(0)');
}, function (_a) {
    var $isOpen = _a.$isOpen;
    return ($isOpen ? '0' : '1');
}, function (_a) {
    var $isOpen = _a.$isOpen;
    return ($isOpen ? 'translateX(-20px)' : 'translateX(0)');
}, function (_a) {
    var $isOpen = _a.$isOpen;
    return ($isOpen ? 'rotate(-45deg)' : 'rotate(0)');
});
export var Overlay = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: ", ";\n  position: fixed;\n  top: 60px; /* Height of Nav */\n  left: 0;\n  width: 100%;\n  height: calc(100% - 60px);\n  background: rgba(0, 0, 0, 0.3);\n  z-index: 10;\n"], ["\n  display: ", ";\n  position: fixed;\n  top: 60px; /* Height of Nav */\n  left: 0;\n  width: 100%;\n  height: calc(100% - 60px);\n  background: rgba(0, 0, 0, 0.3);\n  z-index: 10;\n"])), function (_a) {
    var $isOpen = _a.$isOpen;
    return ($isOpen ? 'block' : 'none');
});
export var Menu = styled.ul(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  list-style: none;\n  align-items: center;\n\n  @media (max-width: 768px) {\n    flex-direction: column;\n    background: #ffffff;\n    position: fixed;\n    top: 60px; /* Height of Nav */\n    left: 0;\n    width: 100%;\n    height: calc(100% - 60px);\n    padding-top: 20px;\n    transition: transform 0.3s ease-in-out;\n    transform: ", ";\n    z-index: 15;\n  }\n"], ["\n  display: flex;\n  list-style: none;\n  align-items: center;\n\n  @media (max-width: 768px) {\n    flex-direction: column;\n    background: #ffffff;\n    position: fixed;\n    top: 60px; /* Height of Nav */\n    left: 0;\n    width: 100%;\n    height: calc(100% - 60px);\n    padding-top: 20px;\n    transition: transform 0.3s ease-in-out;\n    transform: ", ";\n    z-index: 15;\n  }\n"])), function (_a) {
    var $isOpen = _a.$isOpen;
    return ($isOpen ? 'translateX(0)' : 'translateX(-100%)');
});
export var MenuItem = styled.li(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  margin-left: 20px;\n\n  @media (max-width: 768px) {\n    margin: 15px 0;\n  }\n"], ["\n  margin-left: 20px;\n\n  @media (max-width: 768px) {\n    margin: 15px 0;\n  }\n"])));
export var MenuLink = styled(Link)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  text-decoration: none;\n  color: #333333;\n  font-size: 16px;\n  transition: color 0.2s ease-in-out;\n\n  &:hover,\n  &:focus {\n    color: #0066cc;\n  }\n\n  /* Additional styles if needed */\n"], ["\n  text-decoration: none;\n  color: #333333;\n  font-size: 16px;\n  transition: color 0.2s ease-in-out;\n\n  &:hover,\n  &:focus {\n    color: #0066cc;\n  }\n\n  /* Additional styles if needed */\n"])));
// Styled LogoutButton as a button element
export var LogoutButton = styled.button(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  background: none;\n  border: none;\n  color: #cc0000; /* Red color for logout */\n  font-size: 16px;\n  cursor: pointer;\n  padding: 0;\n  transition: color 0.2s ease-in-out;\n\n  &:hover,\n  &:focus {\n    color: #ff3333;\n  }\n\n  /* Remove default button styles */\n  &:focus {\n    outline: none;\n  }\n"], ["\n  background: none;\n  border: none;\n  color: #cc0000; /* Red color for logout */\n  font-size: 16px;\n  cursor: pointer;\n  padding: 0;\n  transition: color 0.2s ease-in-out;\n\n  &:hover,\n  &:focus {\n    color: #ff3333;\n  }\n\n  /* Remove default button styles */\n  &:focus {\n    outline: none;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
