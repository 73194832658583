var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import CTAButton from './CTAButton';
var Section = styled.section(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 80px 20px;\n  text-align: center;\n"], ["\n  padding: 80px 20px;\n  text-align: center;\n"])));
var Headline = styled.h2(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 2rem;\n  margin-bottom: 1rem;\n"], ["\n  font-size: 2rem;\n  margin-bottom: 1rem;\n"])));
var FeaturesContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));\n  gap: 40px;\n  align-items: start;\n  justify-items: center;\n  margin-top: 40px;\n\n  @media (max-width: 768px) {\n    gap: 30px;\n  }\n\n  @media (max-width: 480px) {\n    grid-template-columns: 1fr;\n    gap: 20px;\n  }\n"], ["\n  display: grid;\n  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));\n  gap: 40px;\n  align-items: start;\n  justify-items: center;\n  margin-top: 40px;\n\n  @media (max-width: 768px) {\n    gap: 30px;\n  }\n\n  @media (max-width: 480px) {\n    grid-template-columns: 1fr;\n    gap: 20px;\n  }\n"])));
var Feature = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  text-align: center;\n  max-width: 300px;\n"], ["\n  text-align: center;\n  max-width: 300px;\n"])));
var FeatureImage = styled.img(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 100px;\n  height: 100px;\n  object-fit: contain;\n  margin-bottom: 1rem;\n"], ["\n  width: 100px;\n  height: 100px;\n  object-fit: contain;\n  margin-bottom: 1rem;\n"])));
var FeatureTitle = styled.h3(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-size: 1.25rem;\n  margin-bottom: 0.75rem;\n"], ["\n  font-size: 1.25rem;\n  margin-bottom: 0.75rem;\n"])));
var FeatureDescription = styled.p(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-size: 1rem;\n  color: #555;\n"], ["\n  font-size: 1rem;\n  color: #555;\n"])));
var Features = function (_a) {
    var onJoinWaitlist = _a.onJoinWaitlist;
    return (_jsxs(Section, { children: [_jsx(Headline, { children: "Your Meal Planning Made Simple" }), _jsxs(FeaturesContainer, { children: [_jsxs(Feature, { children: [_jsx(FeatureImage, { src: "../assets/menu-icon.png", alt: "Hyper-Personalized Meal Plans" }), _jsx(FeatureTitle, { children: "Hyper-Personalized Meal Plans" }), _jsx(FeatureDescription, { children: "Using AI, Sporksy tailors meal recommendations to your family\u2019s unique tastes, dietary preferences, and nutritional goals." })] }), _jsxs(Feature, { children: [_jsx(FeatureImage, { src: "/assets/pan-flip.png", alt: "Sustainable and Waste-Free" }), _jsx(FeatureTitle, { children: "Sustainable and Waste-Free" }), _jsx(FeatureDescription, { children: "Minimize food waste and reduce grocery costs by planning smarter, portion-controlled meals." })] }), _jsxs(Feature, { children: [_jsx(FeatureImage, { src: "/assets/open-sign.png", alt: "Shopping Made Easy" }), _jsx(FeatureTitle, { children: "Shopping Made Easy" }), _jsx(FeatureDescription, { children: "Automatically generates grocery lists or connects with your favorite delivery service\u2014so the groceries come to you." })] }), _jsxs(Feature, { children: [_jsx(FeatureImage, { src: "/assets/cooking-pot.png", alt: "Family-Friendly Recipes" }), _jsx(FeatureTitle, { children: "Family-Friendly Recipes" }), _jsx(FeatureDescription, { children: "Simple, healthy, and delicious recipes that even picky eaters will love." })] })] }), _jsx(CTAButton, __assign({ onClick: onJoinWaitlist, style: { marginTop: '40px' } }, { children: "Ready to Simplify Your Meals? Join the Waitlist" }))] }));
};
export default Features;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
