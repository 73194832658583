// src/styles/theme.ts
export var theme = {
    colors: {
        primary: '#ff6f61',
        secondary: '#333333',
        background: '#ffffff',
        text: '#333333',
        hover: '#ff6f61',
        focus: '#ff6f61',
    },
    breakpoints: {
        mobile: '768px',
    },
};
