var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
var Section = styled.section(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 80px 20px;\n  text-align: center;\n"], ["\n  padding: 80px 20px;\n  text-align: center;\n"])));
var Headline = styled.h2(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 2rem;\n  margin-bottom: 2rem;\n"], ["\n  font-size: 2rem;\n  margin-bottom: 2rem;\n"])));
var Testimonial = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  max-width: 700px;\n  margin: 0 auto 2rem auto;\n"], ["\n  max-width: 700px;\n  margin: 0 auto 2rem auto;\n"])));
var Quote = styled.p(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: 1.1rem;\n  font-style: italic;\n  color: #555;\n  margin-bottom: 1rem;\n"], ["\n  font-size: 1.1rem;\n  font-style: italic;\n  color: #555;\n  margin-bottom: 1rem;\n"])));
var Author = styled.p(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-size: 1rem;\n  font-weight: bold;\n"], ["\n  font-size: 1rem;\n  font-weight: bold;\n"])));
var Testimonials = function () {
    var testimonials = [
        {
            quote: "Sporksy has transformed our meal planning. We spend less time in the kitchen and more time with our family!",
            author: "Jane D., Busy Professional",
        },
        {
            quote: "The automated grocery shopping feature is a game-changer. It saves us so much time every week.",
            author: "Mike S., Working Parent",
        },
        {
            quote: "Healthy, delicious meals without the hassle. Sporksy is exactly what we needed!",
            author: "Laura K., Health Enthusiast",
        },
    ];
    return (_jsxs(Section, { children: [_jsx(Headline, { children: "Here\u2019s What People Are Saying" }), testimonials.map(function (testimonial, index) { return (_jsxs(Testimonial, { children: [_jsxs(Quote, { children: ["\"", testimonial.quote, "\""] }), _jsxs(Author, { children: ["- ", testimonial.author] })] }, index)); })] }));
};
export default Testimonials;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
