var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PostItem, PostTitle, PostMeta, PostExcerpt, ReadMoreLink, } from './Blog.styles';
import { Link } from 'react-router-dom';
var BlogPost = function (_a) {
    var id = _a.id, title = _a.title, author = _a.author, date = _a.date, excerpt = _a.excerpt;
    return (_jsxs(PostItem, { children: [_jsx(PostTitle, { children: title }), _jsxs(PostMeta, { children: ["By ", author, " on ", new Date(date).toLocaleDateString()] }), _jsx(PostExcerpt, { children: excerpt }), _jsx(ReadMoreLink, __assign({ as: Link, to: "/Blog/".concat(id), "aria-label": "Read more about ".concat(title) }, { children: "Read More \u00BB" }))] }));
};
export default BlogPost;
