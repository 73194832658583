var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import CTAButton from './CTAButton';
var Section = styled.section(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 80px 20px;\n  background-color: #f9f9f9;\n  text-align: center;\n"], ["\n  padding: 80px 20px;\n  background-color: #f9f9f9;\n  text-align: center;\n"])));
var Headline = styled.h2(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 2rem;\n  margin-bottom: 1rem;\n"], ["\n  font-size: 2rem;\n  margin-bottom: 1rem;\n"])));
var Subheadline = styled.p(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 1.1rem;\n  margin-bottom: 2rem;\n"], ["\n  font-size: 1.1rem;\n  margin-bottom: 2rem;\n"])));
var BodyText = styled.p(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: 1rem;\n  max-width: 800px;\n  margin: 0 auto 2rem auto;\n  line-height: 1.6;\n"], ["\n  font-size: 1rem;\n  max-width: 800px;\n  margin: 0 auto 2rem auto;\n  line-height: 1.6;\n"])));
var AboutUs = function (_a) {
    var onJoinWaitlist = _a.onJoinWaitlist;
    return (_jsxs(Section, { children: [_jsx(Headline, { children: "Why We Built Sporksy" }), _jsx(Subheadline, { children: "We\u2019re two regular guys\u2014one a busy professional and the other a working family man\u2014who needed a solution to simplify meal planning and grocery shopping for ourselves, our friends, and our families." }), _jsx(BodyText, { children: "We\u2019re just like you\u2014constantly juggling the demands of life, work, and family. Between balancing our careers and making time for the people we love, the last thing we wanted was to spend hours stressing over meals." }), _jsx(BodyText, { children: "That's why we built Sporksy\u2014to automate the tedious parts of meal planning and grocery shopping, so you can spend more time enjoying meals, not planning them." }), _jsx(BodyText, { children: "Whether you're a parent looking for quick, healthy dinner ideas, or a busy professional who needs meals ready with minimal effort, Sporksy is designed for you. Our mission is to create a world where everyone can eat well without the hassle, where meals are fun and stress-free, and where sustainability is just part of the process." }), _jsx(CTAButton, __assign({ onClick: onJoinWaitlist }, { children: "Limited spots available for early adopters" }))] }));
};
export default AboutUs;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
