var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
// src/components/AdminDashboard/AdminDashboard.styles.ts
import styled from 'styled-components';
export var DashboardContainer = styled.main(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  max-width: 800px;\n  margin: 50px auto;\n  padding: 2rem;\n  background-color: ", ";\n  border-radius: 8px;\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n  font-family: Arial, sans-serif;\n\n  @media (max-width: ", ") {\n    margin: 20px;\n    padding: 1rem;\n  }\n"], ["\n  max-width: 800px;\n  margin: 50px auto;\n  padding: 2rem;\n  background-color: ", ";\n  border-radius: 8px;\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n  font-family: Arial, sans-serif;\n\n  @media (max-width: ", ") {\n    margin: 20px;\n    padding: 1rem;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.background;
}, function (_a) {
    var theme = _a.theme;
    return theme.breakpoints.mobile;
});
export var Section = styled.section(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-bottom: 2rem;\n"], ["\n  margin-bottom: 2rem;\n"])));
export var SectionTitle = styled.h2(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n  font-size: 1.8rem;\n  margin-bottom: 1rem;\n"], ["\n  color: ", ";\n  font-size: 1.8rem;\n  margin-bottom: 1rem;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.primary;
});
export var Form = styled.form(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n"])));
export var Label = styled.label(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-weight: bold;\n  color: ", ";\n"], ["\n  font-weight: bold;\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.text;
});
export var Input = styled.input(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  padding: 0.5rem;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n  font-size: 1rem;\n  width: 100%;\n\n  &:focus {\n    border-color: ", ";\n    outline: none;\n  }\n"], ["\n  padding: 0.5rem;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n  font-size: 1rem;\n  width: 100%;\n\n  &:focus {\n    border-color: ", ";\n    outline: none;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.primary;
});
export var TextArea = styled.textarea(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  padding: 0.5rem;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n  font-size: 1rem;\n  width: 100%;\n  height: 150px;\n  resize: vertical;\n\n  &:focus {\n    border-color: ", ";\n    outline: none;\n  }\n"], ["\n  padding: 0.5rem;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n  font-size: 1rem;\n  width: 100%;\n  height: 150px;\n  resize: vertical;\n\n  &:focus {\n    border-color: ", ";\n    outline: none;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.primary;
});
export var Button = styled.button(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  padding: 0.75rem 1.5rem;\n  background-color: ", ";\n  color: #ffffff;\n  border: none;\n  border-radius: 4px;\n  cursor: pointer;\n  font-size: 1rem;\n  transition: background-color 0.3s ease;\n\n  &:hover,\n  &:focus {\n    background-color: ", ";\n  }\n\n  &:focus {\n    outline: 2px solid ", ";\n    outline-offset: 2px;\n  }\n\n  &:disabled {\n    background-color: #ccc;\n    cursor: not-allowed;\n  }\n"], ["\n  padding: 0.75rem 1.5rem;\n  background-color: ", ";\n  color: #ffffff;\n  border: none;\n  border-radius: 4px;\n  cursor: pointer;\n  font-size: 1rem;\n  transition: background-color 0.3s ease;\n\n  &:hover,\n  &:focus {\n    background-color: ", ";\n  }\n\n  &:focus {\n    outline: 2px solid ", ";\n    outline-offset: 2px;\n  }\n\n  &:disabled {\n    background-color: #ccc;\n    cursor: not-allowed;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.primary;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.hover;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.focus;
});
export var ErrorMessage = styled.p(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  color: #e63946;\n  font-size: 0.9rem;\n  margin-top: 0.5rem;\n"], ["\n  color: #e63946;\n  font-size: 0.9rem;\n  margin-top: 0.5rem;\n"])));
export var SuccessMessage = styled.p(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  color: #2a9d8f;\n  font-size: 0.9rem;\n  margin-top: 0.5rem;\n"], ["\n  color: #2a9d8f;\n  font-size: 0.9rem;\n  margin-top: 0.5rem;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
