var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { collection, addDoc, getFirestore } from 'firebase/firestore';
// Styled Components
var Modal = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: fixed;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  background-color: white;\n  padding: 30px;\n  border-radius: 8px;\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);\n  z-index: 1000;\n  max-width: 400px;\n  width: 90%;\n  text-align: center;\n\n  @media (max-width: 600px) {\n    padding: 20px;\n  }\n"], ["\n  position: fixed;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  background-color: white;\n  padding: 30px;\n  border-radius: 8px;\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);\n  z-index: 1000;\n  max-width: 400px;\n  width: 90%;\n  text-align: center;\n\n  @media (max-width: 600px) {\n    padding: 20px;\n  }\n"])));
var Overlay = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-color: rgba(0, 0, 0, 0.7);\n  z-index: 999;\n"], ["\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-color: rgba(0, 0, 0, 0.7);\n  z-index: 999;\n"])));
var StyledButton = styled.button(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background-color: ", ";\n  color: ", ";\n  border: none;\n  border-radius: 4px;\n  padding: 10px 20px;\n  font-size: 1rem;\n  cursor: pointer;\n  margin-top: 10px;\n  width: 100%;\n  transition: background-color 0.3s ease;\n\n  &:hover,\n  &:focus {\n    background-color: ", ";\n    outline: none;\n  }\n\n  &:focus {\n    outline: 2px solid #ff6f61;\n  }\n\n  &:disabled {\n    background-color: #ddd;\n    cursor: not-allowed;\n  }\n"], ["\n  background-color: ", ";\n  color: ", ";\n  border: none;\n  border-radius: 4px;\n  padding: 10px 20px;\n  font-size: 1rem;\n  cursor: pointer;\n  margin-top: 10px;\n  width: 100%;\n  transition: background-color 0.3s ease;\n\n  &:hover,\n  &:focus {\n    background-color: ", ";\n    outline: none;\n  }\n\n  &:focus {\n    outline: 2px solid #ff6f61;\n  }\n\n  &:disabled {\n    background-color: #ddd;\n    cursor: not-allowed;\n  }\n"])), function (_a) {
    var $primary = _a.$primary;
    return ($primary ? '#ff6f61' : '#ccc');
}, function (_a) {
    var $primary = _a.$primary;
    return ($primary ? '#fff' : '#333');
}, function (_a) {
    var $primary = _a.$primary;
    return ($primary ? '#ff4b3a' : '#b3b3b3');
});
var ErrorMessage = styled.p(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: red;\n  font-size: 0.9rem;\n  margin-top: 5px;\n"], ["\n  color: red;\n  font-size: 0.9rem;\n  margin-top: 5px;\n"])));
var InputContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  gap: 15px; /* Space between input fields */\n  margin-bottom: 20px; /* Space between input and button */\n"], ["\n  display: flex;\n  flex-direction: column;\n  gap: 15px; /* Space between input fields */\n  margin-bottom: 20px; /* Space between input and button */\n"])));
var StyledInput = styled.input(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 100%;\n  padding: 10px;\n  margin: 0;\n  font-size: 1rem;\n  border-radius: 4px;\n  border: 1px solid #ccc;\n\n  &:focus {\n    outline: 2px solid #ff6f61;\n    border-color: #ff6f61;\n  }\n"], ["\n  width: 100%;\n  padding: 10px;\n  margin: 0;\n  font-size: 1rem;\n  border-radius: 4px;\n  border: 1px solid #ccc;\n\n  &:focus {\n    outline: 2px solid #ff6f61;\n    border-color: #ff6f61;\n  }\n"])));
var SignupModal = function (_a) {
    var onClose = _a.onClose, quizId = _a.quizId, customText = _a.customText;
    var _b = useState(''), name = _b[0], setName = _b[1];
    var _c = useState(''), email = _c[0], setEmail = _c[1];
    var _d = useState(false), loading = _d[0], setLoading = _d[1];
    var handleSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        var db, waitlistRef, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!name || !email) {
                        toast.error('Please fill in both name and email.');
                        return [2 /*return*/];
                    }
                    setLoading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    db = getFirestore();
                    waitlistRef = collection(db, 'waitlist');
                    // Add the user to the waitlist with optional quizId
                    return [4 /*yield*/, addDoc(waitlistRef, {
                            name: name,
                            email: email,
                            quizId: quizId || null,
                            timestamp: new Date(),
                        })];
                case 2:
                    // Add the user to the waitlist with optional quizId
                    _a.sent();
                    toast.success('Successfully signed up for the waitlist!');
                    onClose(); // Close the modal after success
                    return [3 /*break*/, 5];
                case 3:
                    error_1 = _a.sent();
                    console.error('Error adding to waitlist:', error_1);
                    toast.error('Failed to sign up. Please try again later.');
                    return [3 /*break*/, 5];
                case 4:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(_Fragment, { children: [_jsx(Overlay, { onClick: onClose }), _jsxs(Modal, { children: [_jsx("h2", { children: customText || 'Join our waitlist' }), _jsxs(InputContainer, { children: [_jsx(StyledInput, { type: "text", name: "name", placeholder: "Enter your name", value: name, onChange: function (e) { return setName(e.target.value); }, required: true }), _jsx(StyledInput, { type: "email", name: "email", placeholder: "Enter your email", value: email, onChange: function (e) { return setEmail(e.target.value); }, required: true })] }), _jsx(StyledButton, __assign({ onClick: handleSubmit, "$primary": true, disabled: loading }, { children: loading ? 'Submitting...' : 'Join the Waitlist' }))] })] }));
};
export default SignupModal;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
