// client/src/components/mealplanner/MealPlanner.styles.ts
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { keyframes } from 'styled-components';
// 1) Keyframe for spinner rotation
var spin = keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  to {\n    transform: rotate(360deg);\n  }\n"], ["\n  to {\n    transform: rotate(360deg);\n  }\n"])));
// 2) Wrap content in a container
export var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  max-width: 800px;\n  margin: 0 auto;\n  padding: 20px;\n"], ["\n  max-width: 800px;\n  margin: 0 auto;\n  padding: 20px;\n"])));
// 3) Title styling
export var Title = styled.h2(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  text-align: center;\n  color: #2c3e50;\n  margin-bottom: 30px;\n"], ["\n  text-align: center;\n  color: #2c3e50;\n  margin-bottom: 30px;\n"])));
// 4) Container for loading/error/empty states
//    (They share the same flex styling in your CSS)
export var LoadingContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n"], ["\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n"])));
export var ErrorContainer = styled(LoadingContainer)(templateObject_5 || (templateObject_5 = __makeTemplateObject([""], [""])));
export var EmptyContainer = styled(LoadingContainer)(templateObject_6 || (templateObject_6 = __makeTemplateObject([""], [""])));
// 5) Spinner
export var Spinner = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  border: 8px solid #f3f3f3;\n  border-top: 8px solid #2c3e50;\n  border-radius: 50%;\n  width: 60px;\n  height: 60px;\n  animation: ", " 2s linear infinite;\n  margin-bottom: 20px;\n"], ["\n  border: 8px solid #f3f3f3;\n  border-top: 8px solid #2c3e50;\n  border-radius: 50%;\n  width: 60px;\n  height: 60px;\n  animation: ", " 2s linear infinite;\n  margin-bottom: 20px;\n"])), spin);
// 6) Error Message text
export var ErrorMessage = styled.p(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  color: #e74c3c;\n  font-size: 18px;\n  margin-bottom: 20px;\n"], ["\n  color: #e74c3c;\n  font-size: 18px;\n  margin-bottom: 20px;\n"])));
// 7) Retry Button
export var RetryButton = styled.button(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  padding: 10px 20px;\n  background-color: #2c3e50;\n  color: #ffffff;\n  border: none;\n  border-radius: 5px;\n  cursor: pointer;\n  font-size: 16px;\n\n  &:hover {\n    background-color: #1a242f;\n  }\n"], ["\n  padding: 10px 20px;\n  background-color: #2c3e50;\n  color: #ffffff;\n  border: none;\n  border-radius: 5px;\n  cursor: pointer;\n  font-size: 16px;\n\n  &:hover {\n    background-color: #1a242f;\n  }\n"])));
// 8) Table + nested elements
export var StyledTable = styled.table(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  width: 100%;\n  border-collapse: collapse;\n  margin-bottom: 30px;\n\n  th,\n  td {\n    border: 1px solid #bdc3c7;\n    padding: 12px;\n    text-align: left;\n  }\n\n  th {\n    background-color: #2c3e50;\n    color: #ffffff;\n  }\n\n  tr:nth-child(even) {\n    background-color: #f2f2f2;\n  }\n"], ["\n  width: 100%;\n  border-collapse: collapse;\n  margin-bottom: 30px;\n\n  th,\n  td {\n    border: 1px solid #bdc3c7;\n    padding: 12px;\n    text-align: left;\n  }\n\n  th {\n    background-color: #2c3e50;\n    color: #ffffff;\n  }\n\n  tr:nth-child(even) {\n    background-color: #f2f2f2;\n  }\n"])));
// 9) Footer text
export var Footer = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  text-align: center;\n  color: #7f8c8d;\n  font-size: 14px;\n"], ["\n  text-align: center;\n  color: #7f8c8d;\n  font-size: 14px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;
