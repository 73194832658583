var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components';
var ProgressContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  background-color: #ddd;\n  border-radius: 25px;\n  margin-bottom: 2rem;\n  height: 20px;\n"], ["\n  width: 100%;\n  background-color: #ddd;\n  border-radius: 25px;\n  margin-bottom: 2rem;\n  height: 20px;\n"])));
var ProgressFiller = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  height: 100%;\n  width: ", "%;\n  background-color: #ff6f61;\n  border-radius: inherit;\n  transition: width 0.5s ease-in-out;\n"], ["\n  height: 100%;\n  width: ", "%;\n  background-color: #ff6f61;\n  border-radius: inherit;\n  transition: width 0.5s ease-in-out;\n"])), function (_a) {
    var $percentage = _a.$percentage;
    return $percentage;
});
var ProgressBar = React.memo(function (_a) {
    var currentStep = _a.currentStep, totalSteps = _a.totalSteps;
    var percentage = ((currentStep + 1) / totalSteps) * 100;
    return (_jsx(ProgressContainer, __assign({ role: "progressbar", "aria-valuenow": currentStep + 1, "aria-valuemin": 1, "aria-valuemax": totalSteps, "aria-label": "Progress: Step ".concat(currentStep + 1, " of ").concat(totalSteps) }, { children: _jsx(ProgressFiller, { "$percentage": percentage }) })));
});
export default ProgressBar;
var templateObject_1, templateObject_2;
