var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components';
var StyledTextArea = styled.textarea(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  padding: 10px;\n  margin: 10px 0;\n  font-size: 1rem;\n  border-radius: 4px;\n  border: 1px solid ", ";\n  resize: vertical;\n\n  &:focus {\n    outline: 2px solid #ff6f61;\n    border-color: #ff6f61;\n  }\n"], ["\n  width: 100%;\n  padding: 10px;\n  margin: 10px 0;\n  font-size: 1rem;\n  border-radius: 4px;\n  border: 1px solid ", ";\n  resize: vertical;\n\n  &:focus {\n    outline: 2px solid #ff6f61;\n    border-color: #ff6f61;\n  }\n"])), function (_a) {
    var hasError = _a.hasError;
    return (hasError ? 'red' : '#ccc');
});
var TextAreaField = React.memo(function (_a) {
    var name = _a.name, value = _a.value, onChange = _a.onChange, placeholder = _a.placeholder, _b = _a.rows, rows = _b === void 0 ? 4 : _b, _c = _a.required, required = _c === void 0 ? false : _c, ariaDescribedBy = _a.ariaDescribedBy;
    return (_jsx(StyledTextArea, { name: name, value: value, onChange: onChange, placeholder: placeholder, rows: rows, required: required, "aria-required": required, "aria-describedby": ariaDescribedBy, hasError: !!ariaDescribedBy }));
});
export default TextAreaField;
var templateObject_1;
